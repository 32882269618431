import React from 'react';
import { Helmet } from "react-helmet";
import { Link, Navigate } from 'react-router-dom';
import { set, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    updateDoc,
    doc
  } from "firebase/firestore";
  import Notiflix from 'notiflix';
  import { NavLink, useNavigate, useLocation} from "react-router-dom";
  import axios from "axios";
  import { SENDBIRD_INFO } from '../constants/constants';
  import SendBird from 'sendbird';
  import { getAuth, updateProfile } from 'firebase/auth';
  import SendbirdChat from '@sendbird/chat';
  import {
    GroupChannelModule,
    GroupChannelFilter,
    GroupChannelListOrder,
    MessageFilter,
    MessageCollectionInitPolicy
} from '@sendbird/chat/groupChannel';
import { url_checker } from '../utils/messageUtils';


const AccountSettings = ({setUserNickname}) => {

    const location = useLocation();
    const [selectedfile, SetSelectedFile] = useState(null);

    const appId = SENDBIRD_INFO.appId;
    const tokenId = SENDBIRD_INFO.senbirdToken;
    const sb = new SendBird({appId});
    

    useEffect(()=>{
        var readURL = function(input) {
            if (input.files && input.files[0]) {
            var reader = new FileReader();
            SetSelectedFile(input.files[0]);
            reader.onload = async function(e) {
                document.querySelector('.profile-pic').setAttribute('src', e.target.result);
            };
            
            reader.readAsDataURL(input.files[0]);
            }
        };
        
        var uploadButton = document.querySelector(".circle");
        var fileUploadInput = document.querySelector(".file-upload");
        
        var isFileUploadInputClicked = false;
        
        uploadButton?.addEventListener('click', function(e) {
            if (!isFileUploadInputClicked) {
                e.preventDefault();
                fileUploadInput.click();
            }
            isFileUploadInputClicked = false;
        });
        
        fileUploadInput?.addEventListener('click', function() {
            isFileUploadInputClicked = true;
        });
        
        fileUploadInput?.addEventListener('change', function() {
            if (this.files[0]?.size <= 3 * 1024 * 1024) {
                readURL(this);
                document.getElementById('camera_icon').style.display = "none";
                document.querySelector('.img-overlay').style.display = "none";
                document.querySelector('.profile-img-error').style.display = "none";
            }else {
                document.querySelector('.profile-img-error').style.display = "block";
            }
            
        });
    },[location.pathname]);
    

    const validationSchema = Yup.object({
        name: Yup.string().test('custom-validation', 'Please enter your name', function (value) {
            if (inputField.name) {
                return true;
            }
            else {
                return Yup.string().required().isValidSync(value);
            }
        }),
        bio: Yup.string().test('custom-validation', 'Please enter your bio', function (value) {
            if (inputField.bio) {
                return true;
            }
            else {
                return Yup.string().required().isValidSync(value);
            }
        }),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const [userData, setUserdata] = useState();
    const [loading, setLoading] = useState(false);
    const [user, Loading, error] = useAuthState(auth);
    const [imageSrc, setImageSrc] = useState('/assets/images/upload-cover-placeholder.jpg');
    const app_name = SENDBIRD_INFO.app_name;

    if(Loading == true){
        Notiflix.Loading.standard();
    }
    else {
        Notiflix.Loading.remove();
    }

    const navigate = useNavigate();

    const [inputField, setInputField] = useState({
        name: "",
        bio: "",
    });

    const fetchUserdata = async () => {
        const data = query(collection(db, "Users"), where('uid','==',user.uid));
        getDocs(data).then((doc)=>{
           setUserdata(doc?._snapshot?.docs?.keyedMap?.root?.value?.data?.value?.mapValue?.fields);
           setInputField({name:doc?._snapshot?.docs?.keyedMap?.root?.value?.data?.value?.mapValue?.fields.name?.stringValue,bio:doc?._snapshot?.docs?.keyedMap?.root?.value?.data?.value?.mapValue?.fields.bio?.stringValue})
        })
    }

    useEffect(()=>{
        if(user){
            fetchUserdata();
        }
    },[user]);

    const inputHandler = (e) => {
        setInputField({
          ...inputField,
          [e.target.name]: e.target.value,
        });
    };

    const updateDisplayName = () => {
        if (user) {      
          const auth = getAuth(); // Get the authentication instance
          updateProfile(auth.currentUser, {
            displayName: inputField.name
          }).then(async (data) => {
            const sendbirdChat = await SendbirdChat.init({
                appId: SENDBIRD_INFO.appId,
                localCacheEnabled: false,
                modules: [new GroupChannelModule()]
            });
            const userUpdateParams = {};
            userUpdateParams.nickname = user.displayName;
            userUpdateParams.userId = user.uid;
            await sendbirdChat.updateCurrentUserInfo(userUpdateParams);
          }).catch((error) => {
            // An error occurred while updating the display name
            console.error("Error updating display name:", error);
          });
        }
    };

    const submitUserdata = async () => {
        setLoading(true);
        var updatedata;
        if(selectedfile){
            const headers = {
                'Content-Type':'multipart/form-data',
                'Api-token': SENDBIRD_INFO.apiToken
            };
            if (user) {
                const usertoken = await user.getIdToken();
                headers.Authorization = `Bearer ${usertoken}`;
            }
            await axios({
                url:`${SENDBIRD_INFO.mediaUrl}/media/user/profile_image`,
                method: 'post',
                data: {
                    'attachment': selectedfile,
                },
                headers: headers
            })
            .then((res) => res)
            .then(async (response) => {
                document.querySelector('.profile-pic').setAttribute('src', response?.data?.file_url);
                 updatedata = {
                    profileImage:response?.data?.file_url,
                    name:inputField.name,
                    bio:inputField.bio
                  }
                  updateDoc(doc(collection(db, "Users"), user.uid), updatedata).then(()=>{
                    updateDisplayName();
                    Notiflix.Notify.success('Saved Successfully');
                    setLoading(false);
                    navigate('/account');
                });
                  SetSelectedFile(null);
                  
            })
            .catch((err) => {
                Notiflix.Loading.remove();
        });
    }else {
        updatedata = {
            profileImage:document.getElementById('profile_pic').src,
            name:inputField.name,
            bio:inputField.bio
          }
        await updateDoc(doc(collection(db, "Users"), user.uid), updatedata).then((response)=>{
                updateDisplayName();
                Notiflix.Notify.success('Saved Successfully');
                setLoading(false);
                navigate('/account');
            });
    }
}

useEffect(()=>{
    if(!Loading && !user) return navigate("/onboarding");
 },[Loading]); 
 useEffect(() => {
    const checkUrl = async () => {
        if(userData?.profileImage?.stringValue){
            const validUrl = await url_checker(userData?.profileImage?.stringValue);
            console.log(validUrl);
            setImageSrc(validUrl);
        }
       
    };

    checkUrl();
}, [userData]);    
  return (
    <div className='w-[100%] h-fit lg:w-[94%] px-3 lg:mx-[20px] bg-gray-color sm:bg-secondary-color pb-[100px] sm:pb-[0px]'>
         <Helmet>
            <title>{app_name && app_name} Account Settings</title>
        </Helmet> 
        {user ?
        <div className="account-settings-wrapper">
            <div className="account-setting-inner">
                <div className="breadcrumbs-ct hidden sm:block my-4">
                    <ul className='flex items-center'>
                        <li className='text-[14px] list-none text-tertiary-color mr-[7px] cursor-pointer'><Link to={'/'}>Home</Link></li><span>/</span>
                        <li className='text-[14px] list-none text-graydark-color mx-[7px] font-semibold cursor-pointer'><Link to={'/account'}>My Profile</Link></li><span>/</span>
                        <li className='text-[14px] list-none text-tertiary-color mx-[7px] font-semibold'>Account Settings</li>
                    </ul>
                </div>
                <div className="mob-heading flex justify-start items-center my-[14px] sm:hidden">
                    <Link className="ml-[-10px]" to="/account"><span className='icon-leftarrowIcon text-primary-color text-[24px] block mx-2 mt-1'></span></Link>
                    <h2 className='font-bold text-[20px] text-tertiary-color'>Account Settings</h2>
                </div>
                <form action="">
                    <div className="img-field flex flex-col justify-center items-center sm:items-start w-fit my-[8px] mx-auto sm:mx-[0px]">
                        <div className='relative'>
                            <div id="img_circle" className="circle cursor-pointer">
                                <div className="img-overlay absolute w-full h-full bg-[#00000069] z-2">
                                </div>
                                <img className="profile-pic" id="profile_pic" src={imageSrc}/>
                                <img id="camera_icon" src="/assets/images/solar_camera-bold.svg" alt="camera icon" className='camera-icon absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-2' />
                            </div>
                            <div className="p-image">
                                <input className="file-upload" type="file" accept="image/*"/>
                            </div>
                        </div>
                        <span className='profile-img-error hidden text-red-500 sm:text-sm block my-[10px]'>Maximum image size is 3 MB.</span>
                        <span className="text-graydark-color block my-[10px]">{userData?.email?.stringValue}</span>
                    </div>
                    <div className="text-field my-[8px]">
                        <label htmlFor="username" className='text-tertiary-color font-bold text-[18px]'>Name</label>
                        <input name="name" id="name"  maxLength="60"  className='w-full border border-[#DDDFE5] rounded-[5px] mt-[5px] px-3 py-3 bg-secondary-color text-tertiary-color' {...register('name')} onChange={inputHandler}  value={inputField?.name}  />
                        <span className='text-red-500 sm:text-sm block my-[5px]'>{errors.name?.message}</span>
                    </div>
                    <div className="text-field my-[15px]">
                        <label htmlFor="userbio" className='text-tertiary-color font-bold text-[18px]'>Bio</label>
                        <textarea name="bio" id="bio"  rows="5" maxLength="5000" className='w-full border border-[#DDDFE5] outline-none mt-[5px] px-2 rounded-[5px] px-3 py-3 bg-secondary-color text-tertiary-color' {...register('bio')} onChange={inputHandler}  value={inputField?.bio}   >

                        </textarea>
                        <span className='text-red-500 sm:text-sm block my-[5px]'>{errors.bio?.message}</span>
                    </div>
                    <div className="mx-0 lg:mx-0 w-full md:w-full mb-[60px] sm:mb-[0px] pb-[140px] sm:pb-[0px] mt-[20px]"><button onClick={handleSubmit(submitUserdata)} className="w-full h-[48px] inline-flex cursor-pointer items-center justify-center px-4 py-[5px] text-lg font-medium text-center text-white bg-brand-color rounded-lg hover:bg-button-hover  dark:focus:ring-blue-800 mt-[15px]">{loading ? <span className="uploadloader"></span> : 'Save'}</button></div>
                </form>
            </div>
        </div>:!Loading ? <p className='m-[15px] sm:my-[25px] sm:ml-[20px] lg:ml-[0px] pl-0 relative left-[-8px] text-graydark-color font-semibold'>You are not logged In! Please <Link to="/login" className='text-primary-color font-semibold underline'>Login</Link> and try again</p>:null}
    </div>
  )
}

export default AccountSettings