export const SENDBIRD_INFO = {
  appId: process.env.REACT_APP_SEND_BIRD_ID,
  backendUrl:process.env.REACT_APP_BACKEND_URL,
  mediaUrl:process.env.REACT_APP_MEDIA_URL,
  newBotUrl:process.env.REACT_APP_NEW_BOT_URL,
  studioUrl:process.env.REACT_APP_STUDIO_URL,
  senbirdToken:process.env.REACT_APP_SEND_BIRD_TOKEN,
  allowedDomains:['noesis.tech','foxymoron.org','zoomedia.in','thestarterlabs.in','therabbithole.co.in','thestarterlabs.com','rethinkideas.com'],
  apiToken:process.env.REACT_APP_API_TOKEN,
  s3_key:process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  s3_secret:process.env.REACT_APP_AWS_ACCESS_SECRET,
  vapid_id:process.env.REACT_APP_PUSH_NOTIFICATION_TOKEN,
  GTM_ID:process.env.REACT_APP_GTM,
  GA4_ID:process.env.REACT_APP_GA4,
  lightlogoUrl:process.env.REACT_APP_LIGHT_LOGO_URL,
  darklogoUrl:process.env.REACT_APP_DARK_LOGO_URL,
  onboardingImage:process.env.REACT_APP_ONBOARDING_IMAGE,
  applogo_one: process.env.REACT_APP_APP_LOGO_ONE,
  applogo_two: process.env.REACT_APP_APP_LOGO_TWO,
  favIcon_one: process.env.REACT_APP_FAVICON,
  favIcon_two: process.env.REACT_APP_FAICON_ICO,
  SAML_login:process.env.REACT_APP_SAML_LOGIN_STATUS,
  app_name:process.env.REACT_APP_NAME,
  parent_site_name:process.env.REACT_APP_PARENT_SITE_NAME,
  library_page:process.env.REACT_APP_LIBRARYPAGE_STATUS,
  search_page:process.env.REACT_APP_SEARCHPAGE_STATUS,
  firebase : {
    apiKey : process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain : process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId : process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket : process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId : process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId : process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
   
  }
};