import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    updateDoc,
    doc
  } from "firebase/firestore";
  import Notiflix from 'notiflix';
  import { useState, useEffect, useRef } from 'react';
  import { getMessaging, getToken, deleteToken } from 'firebase/messaging';
import SendBird from 'sendbird';
import { SENDBIRD_INFO } from '../constants/constants';
import { useLocation } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const NotificationSettings = () => {

const [user, loading, error] = useAuthState(auth);
const [userData, setUserdata] = useState();
const [userOpen, setUserOpen] = useState(false);
    const [navOpen, setNavOpen] = useState(false);
    const app_name = SENDBIRD_INFO.app_name;
// const [createChannel,userNameInputValue] = useOutletContext();

if(loading == true){
    Notiflix.Loading.standard();
}
else {
    Notiflix.Loading.remove();
}

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(function(registration) {
        console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(function(error) {
        console.error('Service Worker registration failed:', error);
    });
  }

const onNotification =() => {   
    const messaging = getMessaging();

    const appId = SENDBIRD_INFO.appId;
    const vapidKey = SENDBIRD_INFO.vapid_id;
    console.log('hi');
    const sbb = new SendBird({appId});
    console.log(user.uid);
    sbb.connect(user.uid, user.displayName, (user, error) => {

    Notification.requestPermission().then(permission => {

        if (permission === 'granted') {

            getToken(messaging, {vapidKey: vapidKey})
            .then(currentToken => {
            if (currentToken) {
                sbb.registerGCMPushTokenForCurrentUser(currentToken, (response, error) => {
                if(error) console.log(error);
                console.log("Token Registered1:", currentToken)
                });
            }
            })
            .catch(err => {
            console.log('An error occurred while retrieving a token. ', err);
            });
        } else {
            getToken(messaging, {vapidKey: vapidKey})
            .then(currentToken => {
            if (currentToken) {
                sbb.registerGCMPushTokenForCurrentUser(currentToken, (response, error) => {
                if(error) console.log(error);
                console.log("Token Registered2:", currentToken)
                setUserOpen(!userOpen)
                navOpen && setNavOpen(!navOpen)
                });
            }
            })
            .catch(err => {
            console.log('An error occurred while retrieving a token. ', err);
            });
        console.log('Unable to get permission to notify.');
        }
    })
    })
};

// const offNotification = () =>{
//     const messaging = getMessaging();
//     const appId = SENDBIRD_INFO.appId;
//     const vapidKey = SENDBIRD_INFO.vapid_id;
    
//     const sbb = new SendBird({appId});
//     sbb.connect(user.uid, user.displayName, (user, error) => {

//         getToken(messaging, {vapidKey: vapidKey})
//         .then(currentToken => {
//         if (currentToken) {
//                 sbb.unregisterGCMPushTokenForCurrentUser(currentToken, (response, error) => {
//                     if(error) console.log(error);
//                     console.log("Token Unregistered1:", currentToken)
//                 });
//             }
//         })
//         .catch(err => {
//             console.log('An error occurred while retrieving a token. ', err);
//         });
//     });
// }

const offNotification = async () => {
    const messaging = getMessaging();
    const appId = SENDBIRD_INFO.appId;
    const vapidKey = SENDBIRD_INFO.vapid_id;
    const sbb = new SendBird({appId});
   
    sbb.connect(user.uid, user.displayName, async (user, error) => {
        getToken(messaging, {vapidKey: vapidKey})
        .then(currentToken => {
        if (currentToken) {
            sbb.unregisterGCMPushTokenForCurrentUser(currentToken, (response, error) => {
            if(error) console.log(error);
            console.log("Token unRegistered:", currentToken)
            });
        }
        })
        .catch(err => {
            console.log('An error occurred while retrieving a token. ', err);
        });
        // await sbb.setPushTriggerOption('off');
    });
   
  };

const changeAllowNotification = async (e) =>{
    const checkAllCheckbox = document.querySelectorAll('.all-checkbox');
    const checkboxes = document.querySelectorAll('.sub-checkbox'); 
    var inviteStormNotify = document.getElementById('invite_storm_notify'); 
    var messageSentNotify = document.getElementById('message_sent_notify');
    var checkAllNotification = e.currentTarget.checked;
    if(checkAllNotification == true){
        if(inviteStormNotify.checked ==  false){
            setTimeout(()=>{
                inviteStormNotify.click();
            },[250]);
        }
        if(messageSentNotify.checked == false){
            setTimeout(()=>{
                messageSentNotify.click();
            },[500]);
        }
    }
    else {
            if(inviteStormNotify.checked == true){
                setTimeout(()=>{
                    inviteStormNotify.click();
                },[250]);
            }
        if(messageSentNotify.checked == true){
            setTimeout(()=>{
                messageSentNotify.click();
            },[500]);
        }
    }
    const updatedata = {
        allnotifications:checkAllNotification,
    }
    await updateDoc(doc(collection(db, "Users"), user.uid), updatedata).then(()=>{
        fetchUserdata();
        // if(checkAllNotification == true){
        //     checkboxes.forEach(function(checkbox) {
        //         if(!checkbox.checked){
        //             setTimeout(()=>{
        //                 checkbox.click();
        //             },[100]);
        //         }
        //     });
        // }
        // else 
        // {
        //     checkboxes.forEach(function(checkbox) {
        //         if(checkbox.checked){
        //             setTimeout(()=>{
        //                 checkbox.click();
        //             },[100]);
        //         }
        //     });
        // }
        
    });
}

const changeInvitedNotification = async (e) =>{
    var checkInvitedNotification = e.currentTarget.checked;
    const updatedata = {
        invitenotification:checkInvitedNotification,
    }
    await updateDoc(doc(collection(db, "Users"), user.uid), updatedata).then(()=>{
        fetchUserdata();
    });
}

// const changeAcceptNotification = async (e) =>{
//     var checkAcceptNotification = e.currentTarget.checked;
//     const updatedata = {
//         acceptnotification:checkAcceptNotification,
//     }
//     await updateDoc(doc(collection(db, "Users"), user.uid), updatedata).then(()=>{
//         fetchUserdata();
//     });
// }

const changeMessageNotification = async (e) =>{
    var checkMessageInvitation = e.currentTarget.checked;
    const updatedata = {
        messageinvitation:checkMessageInvitation,
    }
    await updateDoc(doc(collection(db, "Users"), user.uid), updatedata).then(()=>{
        fetchUserdata();
        if(checkMessageInvitation == true){
            onNotification();
        }
        if(checkMessageInvitation == false) {
            offNotification();
        }
    });
}


const fetchUserdata = async () => {
    const data = query(collection(db, "Users"), where('uid','==',user.uid));
    getDocs(data).then((doc)=>{
       setUserdata(doc?._snapshot?.docs?.keyedMap?.root?.value?.data?.value?.mapValue?.fields);
    })
}

useEffect(()=>{
    if(user){
        fetchUserdata();
    }
},[user]);

const navigate = useNavigate();

useEffect(()=>{
    if(!loading && !user) return navigate("/onboarding");
 },[loading]); 

  return (
    <div className="w-[100%] h-full lg:w-[94%] px-3 lg:mx-[20px] bg-gray-color sm:bg-secondary-color">
        <Helmet>
            <title>{app_name && app_name} Search Bots</title>
        </Helmet>
        {user ? 
        <div className='notification-settings-wrapper'>
            <div className="notification-settings-inner">
                <div className="breadcrumbs-ct hidden sm:block my-4">
                    <ul className='flex items-center'>
                            <li className='text-[14px] list-none text-graydark-color mr-[7px] font-semibold cursor-pointer'><Link to={'/'}>Home</Link></li><span>/</span>
                            <li className='text-[14px] list-none text-graydark-color mx-[7px] font-semibold cursor-pointer'><Link to={'/account'}>My Profile</Link></li><span>/</span>
                        <li className='text-[14px] list-none text-tertiary-color mx-[7px] font-semibold'>Notifications Settings</li>
                    </ul>
                </div>
                <div className="mob-heading flex justify-start items-center my-[14px] sm:hidden">
                    <Link className="ml-[-10px]" to="/account"><span className='icon-leftarrowIcon text-primary-color text-[24px] block mx-2 mt-1'></span></Link>
                    <h2 className='font-bold text-[20px] text-tertiary-color'>Notifications Settings</h2>
                </div>
                <div className='notification-ct flex justify-between items-center border-b border-b-[#E5E5E5] pt-4 pb-8'>
                    <h3 className='text-[20px] font-bold text-tertiary-color'>Allow Push Notifications</h3>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" checked={userData?.allnotifications?.booleanValue == true} onChange={changeAllowNotification} className="sr-only peer all-checkbox" />
                        <div className="notification-radio-icon w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[5px] after:secondary-color after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-primary-color"></div>
                    </label>
                </div>
                <div className='notification-ct flex justify-between items-center pt-6 pb-6'>
                    <h4 className='text-[16px] font-bold text-tertiary-color'>Notify when invited to a storm</h4>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" id="invite_storm_notify" value="" checked={userData?.invitenotification?.booleanValue == true} onChange={changeInvitedNotification} className="sr-only peer sub-checkbox" />
                        <div className="notification-radio-icon w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[5px] after:secondary-color after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-primary-color"></div>
                    </label>
                </div>
                {/* <div className='notification-ct flex justify-between items-center pt-6 pb-6'>
                    <h4 className='text-[16px] font-bold text-tertiary-color'>Notify when user accepts invitation to storm</h4>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" checked={userData?.acceptnotification?.booleanValue == true} onChange={changeAcceptNotification} className="sr-only peer" />
                        <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[5px] after:secondary-color after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-primary-color"></div>
                    </label>
                </div> */}
                <div className='notification-ct flex justify-between items-center pt-6 pb-6'>
                    <h4 className='text-[16px] font-bold text-tertiary-color'>Notify when a message is sent in a storm</h4>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" id="message_sent_notify" value="" checked={userData?.messageinvitation?.booleanValue == true} onChange={changeMessageNotification} className="sr-only peer sub-checkbox" />
                        <div className="notification-radio-icon w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[5px] after:secondary-color after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-primary-color"></div>
                    </label>
                </div>
            </div>
        </div>:!loading? <p className='m-[15px] sm:my-[25px] sm:ml-[20px] lg:ml-[0px] pl-0 relative left-[-8px] text-graydark-color font-semibold'>You are not logged In! Please <Link to="/login" className='text-primary-color font-semibold underline'>Login</Link> and try again</p>:null}
        
    </div>
  )
}

export default NotificationSettings