import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

// import './katex.min.css';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";
import Login from './auth/login';
import Header from './components/Header';
import Mobfooter from './components/Mobfooter';
import { SENDBIRD_INFO } from './constants/constants';
// import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ThemeProvider } from './contexts/ThemeProvider';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15 * (60 * 1000), 
      cacheTime: 15 * (60 * 1000),
    },
  },
});

const helmetContext = {};

// Dev
ReactGA.initialize(SENDBIRD_INFO.GA4_ID);

// Aplha
// ReactGA.initialize("G-K30DFP9MX4");

Sentry.init({
  dsn: "https://6668335d448e45ba91c1f1973ad9e0d7@o35671.ingest.sentry.io/4505068700696576",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// window.onUsersnapCXLoad = function (api) {
//   api.init();
//   window.Usersnap = api;
// }
// var script = document.createElement('script');
// script.defer = 1;
// script.src = 'https://widget.usersnap.com/global/load/2b44af39-8922-4957-a1ef-3f30ef9c4165?onload=onUsersnapCXLoad';
// document.getElementsByTagName('head')[0].appendChild(script);

ReactDOM.render(
  <ThemeProvider>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById('root')
);


// serviceWorkerRegistration.unregister();
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(function (response) {
      // Service worker registration done
      console.log('Registration Successful', response);
    }, function (error) {
      // Service worker registration failed
      console.log('Registration Failed', error);
    })
}
