import React from 'react'
import { json, useNavigate,BrowserRouter as Router,} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";

const BotQuestions = ({data, createChannel,imagePath, question}) => {

const [user, loading, error] = useAuthState(auth);
const navigate = useNavigate();

const createNewChannel = () =>{
    var dataObject ={name:data?.attributes?.Name, bot_id:[data?.attributes?.bot_id], image_url: data?.attributes?.ProfileImage?.data?.attributes?.url ? imagePath+data?.attributes?.ProfileImage?.data?.attributes?.url : 'https://static.sendbird.com/sample/cover/cover_14.jpg',  defaultQue:question?.Question };
    localStorage.setItem('dataKey', JSON.stringify(dataObject));
    navigate("/login");
}
  
  return (
    <div className="bg-secondary-color explore-bot-card channel-card flex items-start justify-between border rounded-t-md w-auto h-auto mx-0 sm:mx-2 my-2 md:my-4 flex-col relative">
        <div className="img-ct top-sec flex flex-row items-center p-3">
            <h3 className="text-tertiary-color md:text-[17px] font-bold text-left">{question && question?.Question}</h3>
        </div>
      
        <div className="bottom-ct mt-3 w-full flex flex-col justify-between bg-grayshade5-color h-[41px]">
            {user ?
            <a className='flex justify-between items-center h-[100%] cursor-pointer p-3' onClick={()=>createChannel(data?.attributes?.Name, data?.attributes?.bot_id, "pdf", "chanel1", data?.attributes?.ProfileImage?.data?.attributes?.url ? imagePath+data?.attributes?.ProfileImage?.data?.attributes?.url : 'https://static.sendbird.com/sample/cover/cover_14.jpg', question?.Question)}>
                <div className='flex justify-center items-center'>
                    <img src={data?.attributes?.ProfileImage.data?.attributes?.url ? imagePath+data?.attributes?.ProfileImage.data?.attributes?.url:'/assets/images/rethink-default-img.png'} alt="heart-icon" className=' w-[22px] h-[22px] rounded-[50%]' />
                    <span className='text-[#3A3BA7] font-semibold ml-2'>{data && data?.attributes?.Name}</span>
                </div>
                <img src="/assets/images/right-arrow-blue.svg" alt="right arrow" />
            </a>:
            <a className='flex justify-between items-center h-[100%] cursor-pointer p-3' onClick={()=>createNewChannel()}>
                <div className='flex justify-center items-center'>
                    <img src={data?.attributes?.ProfileImage.data?.attributes?.url ? imagePath+data?.attributes?.ProfileImage.data?.attributes?.url:'/assets/images/rethink-default-img.png'} alt="heart-icon" className=' w-[22px] h-[22px] rounded-[50%]' />
                    <span className='text-[#3A3BA7] font-semibold ml-2'>{data && data?.attributes?.Name}</span>
                </div>
                <img src="/assets/images/right-arrow-blue.svg" alt="right arrow" />
            </a>
            }
            
        </div>
    </div>
  )
}

export default BotQuestions