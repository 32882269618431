import { NavLink, useNavigate,useSearchParams } from 'react-router-dom';
import {  createUserWithEmailAndPassword,updateProfile,deleteUser  } from 'firebase/auth';
import { auth,signInWithGoogle,signInWithMicrosoft,signInWithJumpCloud } from '../firebase';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { SENDBIRD_INFO } from '../constants/constants';
import * as Yup from "yup";
import Notiflix from 'notiflix';
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
  } from "firebase/firestore";
import { db } from '../firebase';
import {Helmet} from "react-helmet-async"
import { analytic } from '../utils/Analytics';
import { StoreUserData } from '../utils/StoreUserData';
import { useEffect, useState} from 'react';
import axios from 'axios';
import { useAuthState } from "react-firebase-hooks/auth";

const Signup = () => {
    Notiflix.Loading.remove();
    const navigate = useNavigate();
    
    // const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('');
    const [termsChecked, setTermsChecked] = useState(false);
    const [termsErr, setTermsErr] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const saml_login = process.env.REACT_APP_SAML_LOGIN_STATUS == 'true' ? true : false
    const google_login = process.env.REACT_APP_GOOGLE_STATUS == 'true' ? true : false
    const microsoft_login = process.env.REACT_APP_MICROSOFT_STATUS == 'true' ? true : false
    const emailpass_login = process.env.REACT_APP_EMAILPASS_STATUS == 'true' ? true : false

    const [showpopup, setShowpopup] = useState(searchParams.get("waitlist") ? true : false);
    const handlePopup = ()=>{
        setShowpopup(!showpopup)
    }
    const validationSchema = Yup.object({
        username: Yup.string().required('*Name is required field').required().matches(/^[aA-zZ\s]+$/, "*Only alphabets are allowed for this field"),
        email: Yup.string().required("*Email is required field").email('*Email must be a valid email').matches(/@[^.]*\./, "*Email must be a valid email"),
        password: Yup.string().required("*Password is required field").min(8, '*Password must contain at least 8 characters'),
      });
      const formOptions = { resolver: yupResolver(validationSchema) };
      const { register, handleSubmit, formState } = useForm(formOptions);
      const { errors } = formState;
      const [isLoading, setIsLoading] = useState(false);

      const [usedEmail, setUsedEmail] = useState(null);
      const[darklBrandlogo, setDarkbrandlogo] = useState();
      const app_name = SENDBIRD_INFO.app_name;

    const onSubmit = async (e) => {
    //   e.preventDefault()
    if(termsChecked){
        setTermsErr(false);
        setIsLoading(true);
        const q = query(collection(db, "Whitelist"), where("email", "==", email));
        const docs = await getDocs(q);
    
        const user = await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                const data ={
                    "uid":user.uid,
                  }
                  analytic("signup",data,"signUpWithEmail");
                  setIsLoading(false);
                return user;
                            // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log("check error massage a ="+error.code);
                setUsedEmail(error.code);
                setIsLoading(false);
                // ..
            });
          const address = email.split('@').pop()
          if (docs.docs.length === 0 && !allowDomainList.includes(address)) {
            console.log(address);
    
            await addDoc(collection(db, "Waitlist"), {
                email: email,
              })
            await  deleteUser(user)
            // await signOut(auth)
            window.location = "/signup?waitlist=true"
            setIsLoading(false);
          }else{
            await updateProfile(user, {
                displayName: username
            });
            //Store User data in database
            const qUser = query(collection(db, "Users"), where("uid", "==", user.uid));
            const qDocs = await getDocs(qUser);
    
            if (qDocs.docs.length === 0) {
              await StoreUserData(user,db,'email')
            }
            try {
                const usertoken = await user?.getIdToken(true);
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${SENDBIRD_INFO.newBotUrl}/admin/users/platform`,
                    headers: {
                        Authorization: `Bearer ${usertoken}`,
                        'Api-token': SENDBIRD_INFO.apiToken,
                    },
                };
                axios.request(config)
                    .then((response) => {
                        navigate("/");
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                } catch (error) {
                    console.log(error.message);
                    throw error;
                }
          }
    } else {
        setTermsErr(true);
    }
        
      
    }

    const handleTermsChange = (e) => {
        setTermsChecked(!termsChecked);
    }

    const handleGoogleSignIn = () => {
        if(termsChecked){
            signInWithGoogle();
            setTermsErr(false);
        } else {
            setTermsErr(true);
        }
    }

    const handleMicrosoftSignIn = () => {
        if(termsChecked){
            signInWithMicrosoft();
            setTermsErr(false);
        } else {
            setTermsErr(true);
        }
    }

    const [allowDomainList, setAllowDomainList] = useState();
    var domainArr = [];
    const getDomains = async () => {
            // console.log("b called");
            // Notiflix.Loading.standard(
        
            const headers = {
                'Api-token': SENDBIRD_INFO.apiToken,
                'Content-Type': 'application/json',
            };
            axios({
                url: `${SENDBIRD_INFO.newBotUrl}/domains_all`,
                method: 'get',
                headers: headers
            })
            .then((res) => res)
            .then((response) => {
               for(let i=0; i<response.data.data.length; i++){
                    domainArr.push(response?.data?.data[i]?.attributes?.Domains)
               }
               setAllowDomainList(domainArr);
            })
            .catch((err) => {
                console.log(err.message + "file-error");
            });
    }

    useEffect(()=>{
        getDomains();
        setDarkbrandlogo(SENDBIRD_INFO.darklogoUrl);
    },[]);

    const [loggedInUser, loading, error] = useAuthState(auth);
 
  return (
    <>
    {(!loading && !loggedInUser) ? <main className='flex justify-center h-full w-full mx-auto bg-blue-50 min-h-[810px] md:min-h-[760px]'> 
        <Helmet>
            <title>Register {app_name && app_name} - AI-powered platform for enhanced brainstorming sessions</title>
        </Helmet>
        <div className="flex min-h-full w-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md space-y-8">
                <div>
                    <a href="/">
                        {darklBrandlogo && darklBrandlogo?.includes(".png") || darklBrandlogo?.includes(".svg")? <img src={darklBrandlogo} className="mx-auto h-[80px] w-auto"/> : null}
                    </a>
                    <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-600">Sign up to your account</h2>
                
                </div>
                
                <form className="mt-8 space-y-6" action="#" method="POST">
                <input type="hidden" name="remember" value="true"/>
                <div className='flex flex-wrap justify-center'>
                {google_login ? 
                    <div className="w-full sm:w-1/2 sm:pl-2 sm:mb-2">
                        <button
                            onClick={signInWithGoogle}
                            className="flex rounded justify-center sm:justify-around items-center w-full bg-red-700 hover:bg-red-800 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline text-sm"
                            type="button"
                        ><span>
                            <img width={15} className="h-auto mr-3 sm:mr-0" src="/assets/images/googleIcon.png" alt="googlePlus"/>   
                        </span>
                        Sign up with Google
                        </button>
                    </div>
                    : null}
                    {microsoft_login ? 
                    <div className="w-full mt-3 sm:mt-0 sm:w-1/2 sm:pl-2 sm:mb-2">
                        <button
                            onClick={signInWithMicrosoft}
                            className="flex rounded justify-center sm:justify-around items-center w-full bg-gray-900 hover:bg-gray-900 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline text-sm"
                            type="button"
                        >
                            <span>
                                <img width={15} className="h-auto mr-3 sm:mr-0" src="/assets/images/ms-white.png" alt="googlePlus"/>   
                            </span>
                            Sign in with Microsoft
                        </button>
                    </div>
                    : null}
                    {saml_login ? 
                    <div className="w-full mt-3 sm:mt-0 sm:w-1/2 sm:pl-2 sm:mb-2">
                        <button
                            onClick={signInWithJumpCloud}
                            className="flex rounded justify-center sm:justify-around items-center w-full bg-gray-900 hover:bg-gray-900 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline text-sm"
                            type="button"
                        >
                            Sign in with SAML
                        </button>
                    </div>:null}
                    {(google_login || microsoft_login || saml_login) && emailpass_login ?
                    <div className="relative border-b w-full h-1 flex items-center justify-center mt-7">
                        <span className=" bg-blue-50 px-2 text-gray-400">OR</span>
                    </div>:null}
                </div>
                {emailpass_login? <>
                <div className=" rounded-md">
                    <div className='mb-3 '>
                        <label htmlFor="userme" className="sm:text-sm opacity-70 font-bold">Your name </label>
                        <input id="username" name="username" value={username} {...register('username')}  onChange={(e) => setUsername(e.target.value)} type="text"  required className="shadow-sm relative block w-full rounded-md border-0 py-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-md sm:leading-6" placeholder="Enter your name"/>
                        <span className='text-red-500 sm:text-xs'>{errors.username?.message}</span>

                    </div>
                    <div className='mb-3'>
                        <label htmlFor="email-address" className="sm:text-sm opacity-70 font-bold">Email address </label>
                        <input id="email-address" name="email" {...register('email')} value={email}  onChange={(e) => setEmail(e.target.value)}  type="email" autoComplete="email" required className="shadow-sm relative block w-full rounded-md border-0 py-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-md sm:leading-6" placeholder="Enter email address"/>
                        <span className='text-red-500 sm:text-xs'>{errors.email?.message}</span>
                    </div>
                        
                    <div className=''>
                        <label htmlFor="password" className="sm:text-sm opacity-70 font-bold">Password </label>
                        <input id="password" name="password"  value={password} {...register('password')}  onChange={(e) => setPassword(e.target.value)}  type="password" autoComplete="current-password" required className="shadow-sm relative block w-full rounded-md border-0 py-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-md sm:leading-6" placeholder="Enter password"/>
                        <span className='text-red-500 sm:text-xs'>{errors.password?.message}</span>
                    </div>
                </div>

                {/* <div className="flex items-center justify-between">
                    <div className="flex items-center">
                    <input id="remember-me" name="remember-me" type="checkbox"  className="h-4 w-4 rounded border-gray-300 text-primary-color focus:ring-primary-color"/>
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">Remember me</label>
                    </div>

                    <div className="text-sm">
                        <a href="#" className="font-medium text-primary-color hover:text-indigo-500">Forgot your password?</a>
                    </div>
                </div> */}

                <div className='pb-2 flex flex-col justify-center items-start'>
                    <div className='flex items-center'>
                        <input id="tnc" name="tnc" type='checkbox' className='h-4 w-4 rounded border-gray-300 text-primary-color focus:ring-primary-color cursor-pointer' checked={termsChecked} onChange={handleTermsChange}/>
                        <label htmlFor="tnc" className="ml-2 block text-sm text-gray-900">I agree to the Terms and Condition.</label>
                    </div>
                    {termsErr && <span className='text-red-500 sm:text-xs mt-1'>Please agree to the terms and condition.</span>}
                </div>
                {usedEmail == 'auth/email-already-in-use' ? <span className='text-red-500 sm:text-xs block !mt-[12px]'>Email already in use. Please log in or use a different email.</span>:null}
                <div>
                    <button type="submit"  onClick={handleSubmit(onSubmit)} className="group relative flex w-full justify-center rounded-md bg-brand-color py-2 px-3 text-md font-semibold text-white hover:bg-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color h-[40px]">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <svg className="h-5 w-5 text-button-hover group-hover:text-button-hover" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clipRule="evenodd" />
                        </svg>
                    </span>
                    {isLoading ? <span className="uploadloader"></span> : 'Sign Up'}
                    </button>
                </div>
                <div>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Already have an account? 
                        <a href="#" className="font-medium text-primary-color hover:text-button-hover pl-1">  <NavLink to="/login">Login here.</NavLink></a>
                    </p>
                </div></>: null}
                </form>
                <span className='poweredby-para flex justify-center items-center block m-auto  relative bottom-[10px] text-[#444444] text-[15px]'>Powered by <a href="https://brainstormer.io/" target='_blank'><img src="/assets/images/logo_new.svg" className='ml-2 w-[145px]'></img></a></span>
            </div>
        </div>       
        <div className={`overlay ${showpopup ? 'flex':'hidden'}`}>
             <div className="overlay-content relative rounded-md">
             <span  onClick={handlePopup} className="close-icon text-lg font-bold px-2 py-0 absolute right-1 top-1 rounded-full align-middle cursor-pointer text-tertiary-color">✕</span>
            
             <>
               
                <h4 className="text-lg font-bold mb-3 text-center text-tertiary-color">Thank you for your interest in {app_name && app_name}.</h4>
                   </>
                    <div>
                        <p className='text-center text-tertiary-color'>We are currently in a beta testing phase and have opened the app to a small but growing set of users. We have added your email address to our waitlist and will inform you via email when your account has been approved.</p>
                        {/* <button onClick={() =>{ 
                            window.location.href = `https://brainstormer.io/`; 
                        }} className='group relative flex text-center mx-auto justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white hover:bg-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>Visit Brainstormer.io</button> */}
                    </div>
                   
             </div>
        </div>
    </main>: !loading ? <div className="loader-ct h-[100%] absolute top-[-25px] left-0">
            <img src='/assets/images/loader3.gif' width={30} height={30} alt='loader' loading="lazy"></img>   
        </div> :null}
    </>
  )
}
 
export default Signup