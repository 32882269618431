import React from 'react'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { useState, useEffect } from 'react';
import { SENDBIRD_INFO } from '../constants/constants';

const HelpandSupport = () => {

    const validationSchema = Yup.object({
            userquery: Yup.string().required('*Please enter your query').required(),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const app_name = SENDBIRD_INFO.app_name;

    const onSubmit = async (e) => {
    // handleSendIvite({userName,useremailId});
    }

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(()=>{
        if(!loading && !user) return navigate("/onboarding");
    },[loading]); 

  return (
    <div className='w-[100%] h-full lg:w-[94%] px-3 lg:mx-[20px] bg-gray-color sm:bg-secondary-color'>
        <Helmet>
            <title>{app_name && app_name} Help and Support</title>
        </Helmet>
        <div className='help-support-wrapper'>
            <div className="help-support-inner">
                <div className="breadcrumbs-ct hidden sm:block my-4">
                    <ul className='flex items-center'>
                        <li className='text-[14px] list-none text-graydark-color mr-[7px] font-semibold cursor-pointer'><Link to={'/account'}>Account</Link></li><span>/</span>
                        <li className='text-[14px] list-none text-tertiary-color mx-[7px] font-semibold'>Help & Support</li>
                    </ul>
                </div>
                <div className="mob-heading flex justify-start items-center my-[14px] sm:hidden">
                    <a className="ml-[-10px]" href="/account"><img src="/assets/images/leftArrow.svg" alt="left-arrow-icon" /></a>
                    <h2 className='font-bold text-[20px]'>Help & Support</h2>
                </div>
                <h2 className='text-tertiary-color text-[20px] font-bold mb-[8px]'>Send us your query</h2>
                <p className='text-graydark-color text-[15px] font-semibold max-w-[340px]'>We will contact you via email  in the next 1 - 2 business days.</p>
                <form action="" className='flex flex-col justify-end'>
                    <textarea name="query" id=""  rows="5" maxLength="5000" className='w-full border border-[#DDDFE5] my-2 px-2' {...register('userquery')}>

                    </textarea>
                    <span className='text-red-500 sm:text-sm'>{errors.userquery?.message}</span>
                    <button onClick={handleSubmit(onSubmit)} className='w-[110px] lg:w-[200px] xl:w-[300px] bg-primary-color rounded-[6px] h-[42px] flex justify-center items-center font-semibold hover:bg-opacity-75 mt-[12px] p-0 text-[18px] self-end'>Send</button>
                </form>
                <div className='w-full my-[30px]'>
                    <span className='text-graydark-color text-[18px] text-center w-full block my-4 text-bold'>or</span>
                </div>
                <div className='flex justify-between items-center'>
                    <div className='w-[65%] sm:w-[70%] lg-w-auto'>
                        <h2 className='text-tertiary-color text-[20px] font-bold mb-[8px]'>Talk to our Support Bot</h2>
                        <p className='text-graydark-color text-[15px] font-semibold'>Talk to our ${app_name && app_name} support bot 24 x 7</p>
                    </div>
                    <button className='w-[110px] lg:w-[200px] xl:w-[300px] bg-primary-color rounded-[6px] h-[42px] flex justify-center items-center font-semibold hover:bg-opacity-75 p-0 px-[0px]'>Chat Now</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HelpandSupport