import moment from 'moment';
import { SENDBIRD_INFO } from '../constants/constants';
const { S3Client, GetObjectCommand } = require("@aws-sdk/client-s3");
const { getSignedUrl } = require("@aws-sdk/s3-request-presigner");
const { parse } = require('url');

export const protectFromXSS = (text) => {
    return text
        .replace(/\&/g, '&amp;')
        .replace(/\</g, '&lt;')
        .replace(/\>/g, '&gt;')
        .replace(/\"/g, '&quot;')
        .replace(/\'/g, '&apos;');
};
// Old Function
// export const timestampToTime = (timestamp) => {
//     const now = new Date().getTime();
//     const nowDate = moment.unix(now.toString().length === 13 ? now / 1000 : now).format('MM/DD');
//     let date = moment.unix(timestamp.toString().length === 13 ? timestamp / 1000 : timestamp).format('MM/DD');
//     if (date === 'Invalid date') {
//         date = '';
//     }
//     return nowDate === date
//         ? moment.unix(timestamp.toString().length === 13 ? timestamp / 1000 : timestamp).format('HH:mm')
//         : date;
// };

export const timestampToTime = (timestamp) => {
    const now = new Date().setHours(0,0,0,0);
    const last = new Date(timestamp).setHours(0,0,0,0);
    let m =  moment.unix(last.toString().length === 13 ? last / 1000 : last)
    let m2 =  moment.unix(now.toString().length === 13 ? now / 1000 : now)

    const nowDate = moment.unix(now.toString().length === 13 ? now / 1000 : now).format('DD-MM');
    let date = moment.unix(last.toString().length === 13 ? last / 1000 : last).format('DD-MM');

    const nowDatebyweek = m2.diff(m, 'days');
    if (date === 'Invalid date') {
        date = '';
    }
    // const diffTime = Math.abs(m2.format('Y-m-d') - m.format('Y-m-d'));
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
   
    return nowDate === date
        ? "Today "+ moment.unix(timestamp.toString().length === 13 ? timestamp / 1000 : timestamp).format('HH:mm'):
        nowDatebyweek == 1?
        "Yesterday":
        (nowDatebyweek <= 7 && nowDatebyweek != 1) ?
        m.format('dddd')
        :  m.format('DD-MM-YY');
    
};

const isValidS3Url = (url) => {
    const s3Pattern = /^https?:\/\/([^.]+)\.s3\.([^.]+\.)?amazonaws\.com\/(.+)$/;
    const altPattern = /^https?:\/\/s3\.([^.]+\.)?amazonaws\.com\/([^/]+)\/(.+)$/;
    return s3Pattern.test(url) || altPattern.test(url);
};
const isAbsoluteUrl = (url) => {
    // Pattern to check if the URL starts with a scheme (e.g., http, https, ftp, etc.)
    const absoluteUrlPattern = /^[a-zA-Z][a-zA-Z\d+\-.]*:/;
    return absoluteUrlPattern.test(url);
};
export const url_checker = async (url) => {
    // console.log(url)
    if (isValidS3Url(url)) {
        const parsedUrl = parse(url);
        let bucketName, objectKey, region,objectKeyParts;
        // console.log(parsedUrl,'parsedUrl')
        if (parsedUrl.hostname.endsWith('amazonaws.com')) {
            [bucketName] = parsedUrl.hostname.split('.');
            objectKey = parsedUrl.pathname.slice(1);
            [,,region] = parsedUrl.hostname.split('.');
        } else {
            [, bucketName, ...objectKeyParts] = parsedUrl.pathname.split('/');
            objectKey = objectKeyParts.join('/');
            [,region] = parsedUrl.hostname.split('.');
        }
        try {
            const s3Client = new S3Client({
            region,
            credentials: {
                accessKeyId: SENDBIRD_INFO.s3_key,
                secretAccessKey: SENDBIRD_INFO.s3_secret,
            },
            });
        
            const command = new GetObjectCommand({
            Bucket: bucketName,
            Key: objectKey,
            });
        
            const signedUrl = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
        
            return signedUrl;
        } catch (error) {
        console.error("Error generating signed URL:", error);
        }
    }else{
        if(isAbsoluteUrl(url)){
            return url;
        }else{
            return SENDBIRD_INFO.studioUrl+""+url;
        }
    }
};

export const handleEnterPress = (event, callback) => {
    if (event.key === 'Enter' && event.target.value != '') {
        callback()
    }
}