import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Logout from './auth/logout';
import Login from './auth/login';
import Signup from './auth/signup';
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import Onboarding from './components/Onboarding';

import MainWindow from './components/MainWindow';
import Botlist from './components/Botlist';
import Categorylist from './components/Categorylist';
import TagManager from 'react-gtm-module'
import Botprofile from './components/Botprofile';
import {Helmet} from "react-helmet-async";
import CreatorProfile from './components/CreatorProfile';
import Catlist from './components/Catlist';
import Search from './components/Search';
import HelpandSupport from './components/HelpandSupport';
import Help from './components/Help';
import NotificationSettings from './components/NotificationSettings';
import AccountSettings from './components/AccountSettings';
import Account from './components/Account';
import AccountDashboard from './components/AccountDashboard';
import Feedback from './components/Feedback';
import BotChannelList from './components/BotChannelList';
import { SENDBIRD_INFO } from './constants/constants';
import { useEffect } from 'react';

import Home from './Home';
import GroupChannelRegisterUnregisterOperator from './samples/GroupChannelRegisterUnregisterOperator'
// import { useTheme } from './contexts/ThemeProvider';


const App = () => {

 // const { setCustomProperties } = useTheme();

  // function getTheme() {
  //   if (!('theme-set-user' in localStorage)) {
  //     if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  //       window.localStorage.setItem('data-theme', 'dark');
  //       return 'dark';
  //     } else {
  //       window.localStorage.setItem('data-theme', 'light');
  //       return 'light';
  //     }
  //   } else {
  //     return window.localStorage.getItem('data-theme') || 'light';
  //   }
  // }

  // window.onload = () => {
  //   const html = document.querySelector('html');
  //   const theme = getTheme();
  //   if (html) html.setAttribute('data-theme', theme);
  //   setCustomProperties(theme);
  // };

  const navigate = useNavigate();
	const location = useLocation();
  const tagManagerArgs = {
    gtmId: SENDBIRD_INFO.GTM_ID
  }
  TagManager.initialize(tagManagerArgs)

  useEffect(() => {
    const faviconUrl = SENDBIRD_INFO.favIcon_one

    const faviconICO = SENDBIRD_INFO.favIcon_two
    const logoUrl192 = SENDBIRD_INFO.applogo_one;
    const logoUrl512 = SENDBIRD_INFO.applogo_two;
    const primary_color = SENDBIRD_INFO.primary_color;
    const app_name = SENDBIRD_INFO.app_name;

    const manifest = {
      "short_name": `${app_name && app_name} App`,
      "name": `${app_name && app_name} App`,
      "icons": [
        {
          "src": faviconICO,
          "sizes": "64x64 32x32 24x24 16x16",
          "type": "image/x-icon"
        },
        {
          "src": logoUrl192,
          "sizes": "192x192",
          "type": "image/png",
        },
        {
          "src": logoUrl512,
          "type": "image/png",
          "sizes": "512x512"
        },
      ],
      "start_url": ".",
      "display": "standalone",
      "theme_color": `${primary_color}`,
      "background_color": "#ffffff",
    };
    
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = faviconUrl;

    // Remove any existing favicon element if it exists
    const existingFavicon = document.querySelector('link[rel="icon"]');
    if (existingFavicon) {
      document.head.removeChild(existingFavicon);
    }
    document.head.appendChild(link);

    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    const manifestElement = document.querySelector('link[rel="manifest"]');
    if (manifestElement) manifestElement.setAttribute('href', manifestURL);

  }, []);


  return (
    <div className="flex">
      {/* <Helmet>
          <title>Brainstormer - AI-powered platform for enhanced brainstorming sessions</title>
          <meta name="description" content="Brainstormer is a revolutionary AI platform that facilitates chat-based brainstorming sessions with customizable AI agents. It supports a wide range of pre-defined agents tailored for specific use cases such as Advertising Agent, Code Agent, or even Knowledge Oracle Agents for specialized topics. Brainstormer allows users to upload PDF documents to enhance agents on the fly and provides expert insights and guidance for a richer brainstorming session. Join our alpha testing program to help shape the future of AI-enhanced brainstorming!" />
          <meta property="og:title" content="Brainstormer - AI-powered platform for enhanced brainstorming sessions"></meta>
          <meta name="og:description" content="Brainstormer is a revolutionary AI platform that facilitates chat-based brainstorming sessions with customizable AI agents. It supports a wide range of pre-defined agents tailored for specific use cases such as Advertising Agent, Code Agent, or even Knowledge Oracle Agents for specialized topics. Brainstormer allows users to upload PDF documents to enhance agents on the fly and provides expert insights and guidance for a richer brainstorming session. Join our alpha testing program to help shape the future of AI-enhanced brainstorming!" />
          <meta property='og:image' content='../assets/images/logo512.png'></meta>
      </Helmet> */}
      <Routes>
        <Route path='/' element={<MainWindow />}>
            <Route exact path='/:id' element={<Catlist/>} />
            {/* <Route exact path='/library' element={<Categorylist />} /> */}
            {/* <Route path='/library/category/:slug' element={<Botlist />} /> */}
            <Route exact path='/botprofile/:slug' element={<Botprofile />} />
            <Route exact path='/creatorprofile/:slug' element={<CreatorProfile />} />
            <Route path='/search' element={<Search />} />
            <Route path='/account' element={<Account />} />
            <Route exact path="/chat" element={<BotChannelList />} />
            {/* <Route path='/account/help-and-support' element={<HelpandSupport />} /> */}
            {/* <Route path='/account/help' element={<Help />} /> */}
            {/* <Route path='/account/notification-settings' element={<NotificationSettings />} /> */}
            <Route path='/account/account-settings' element={<AccountSettings />} />
            {/* <Route path='/account/dashboard' element={<AccountDashboard />} /> */}
            {/* <Route path='/account/feedback' element={<Feedback />} /> */}
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/onboarding' element={<Onboarding/>}/>
        <Route path='/homesample' element={<Home/>}/>
        <Route path='/group-channel-register-unregister-operator' element={<GroupChannelRegisterUnregisterOperator/>}/>

        
        {/* <Route exact path='/library' element={<Categorylist/>} /> */}
        {/* <Route exact path='/creatorprofile/' element={<CreatorProfile />} /> */}
      </Routes>
    </div>
  );
}

export default App;
