import React from 'react';
import { useState, useEffect,useCallback, useRef} from 'react';
import { timestampToTime, handleEnterPress } from '../utils/messageUtils';
import { json, useNavigate,BrowserRouter as Router,
    Link,
    NavLink,
    useParams,
    useLocation} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { SENDBIRD_INFO } from '../constants/constants';

const BotChannelList = ({
    channels,
    handleJoinChannel,
    handleDeleteChannel,
    handleLoadMemberSelectionList,
    openChatList,
    dataHandleShowListItem,
    userNameInputValue,
    handleSideBar,
    currentlyJoinedChannel
}) => {
    const [navOpen, setNavOpen] = useState(false);
    const [userOpen, setUserOpen] = useState(false);
    const [dataChannel,setDataChannel] = useState();
    const [filteredList, setFilteredList] = useState(channels);
    const [searchValue,setSearchValue] = useState("");

    const [deviceType, setDeviceType] = useState('');
    const app_name = SENDBIRD_INFO.app_name;
    
    useEffect(()=>{
        const userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) {
        setDeviceType('Android');
    } 
    else if (/iPhone/i.test(userAgent)) {
        setDeviceType('IOS');
        const userAgent = navigator.userAgent.toLowerCase();
    } else if (/Windows Phone/i.test(userAgent)) {
        setDeviceType('Windows Phone');
    } else {
        setDeviceType('Other');
    }
    },[]);


    useEffect(()=>{
        setFilteredList(channels)
        if(searchValue){
            setFilteredList(searchValue)
        }else{
            setFilteredList(channels)
        }
       
    },[channels])

    const filterBySearch = (event) => {
        // Access input value
        const query = event.target.value;
      
        // Create copy of Storms list
        var updatedList = [...channels];
        // Include all elements which includes the search query
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
        // Trigger render with updated values
        setFilteredList(updatedList);
        setSearchValue(updatedList)
      };

    var checkTags = true;    

    const exploreChannel = () => {
        document.querySelector('.channel').style.zIndex = '1';
    }
 
    function isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;
          var c = a.childNodes
          if (c[0].nodeType == 1){
            return true; 
          }
        
        return false;
    }

    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    useEffect(()=>{
        if(!loading && !user) return navigate("/onboarding");
     },[loading]); 

    return (
        (deviceType == "IOS" || deviceType == "Android" || deviceType == "Windows Phone") ?
        <div className='channel-list flex flex-col justify-between'>
            <div className='h-[90%] pb-[20px]'>
                {/* <div className="hidden md:flex items-center justify-between secondary-color ">
                <img className="mx-4 my-2 w-auto p-1 brainstrom-logo" src="/assets/images/logo_new.svg" alt="brainstromapp"/>
                    <div>

                      {userNameInputValue ? <ProfileIcon userid={userNameInputValue.uid} username={userNameInputValue.displayName }/> : null }  
                        
                    </div>
                </div> */}

                {/* <div className="mb-logo-section">
                    <img onClick={exploreChannel} className="" src="/assets/images/cross.svg" alt="brainstromapp"/>
                    <h1 className='mb-heading sm:text-xl text-tertiary-color font-bold px-5 my-3'>My Storms</h1>

                    <div>
                        {userNameInputValue ? <ProfileIcon userid={userNameInputValue.uid} username={userNameInputValue.displayName }/> : null }  
                    </div>


                </div> */}

                {channels.length !== 0 ?
                <>
                <h2 className='block text-[18px] sm:text-xl text-tertiary-color font-bold px-5 my-3 mt-4'>My Storms</h2>
                
                <div className="search-header text-center relative mb-[10px]">
                    <span className="absolute inset-y-0 right-6 flex items-center pr-4">
                        <svg aria-hidden="true" className="w-4 h-4 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </span>
                    <input id="search-box"  placeholder="Search" className='w-[90%] mx-auto focus:ring-blue-600 focus:border-blue-500 bg-secondary-color text-tertiary-color' onChange={filterBySearch} />
                </div>
                </>
                : null}
                
                
                <div className={`${channels.length === 0 ? 'channel-list-without-search text-tertiary-color':'channel-list-ct'}`} >
                    {channels.length === 0?
                        <div className='px-9 flex h-full items-center flex-col justify-center'>
                            <div className=''>
                                <img src="/assets/images/brainstormer-logo3.png" alt="brainstorm logo img" className='mx-auto' />
                                <h3 className='text-left font-bold text-[18px] mt-4 mb-1'>Welcome to {app_name && app_name}</h3>
                                <p className='text-left font-light text-sm mb-5'>Here’s all you can do:</p>
                                <ul className='text-left font-light text-sm mb-5 leading-loose  ml-5'>
                                    <li className='list-none pl-4 relative before:block before:absolute before:left-0 before:top-[0.62rem] before:w-[8px] before:h-[8px] before:rounded-full before:bg-texthighlight-color'>Create new storm </li>
                                    <li className='list-none pl-4 relative before:block before:absolute before:left-0 before:top-[0.62rem] before:w-[8px] before:h-[8px] before:rounded-full before:bg-texthighlight-color'>Chat with bots</li>
                                    <li className='list-none pl-4 relative before:block before:absolute before:left-0 before:top-[0.62rem] before:w-[8px] before:h-[8px] before:rounded-full before:bg-texthighlight-color'>Upload PDF</li>
                                    <li className='list-none pl-4 relative before:block before:absolute before:left-0 before:top-[0.62rem] before:w-[8px] before:h-[8px] before:rounded-full before:bg-texthighlight-color'>Invite friends</li>
                                    <li className='list-none pl-4 relative before:block before:absolute before:left-0 before:top-[0.62rem] before:w-[8px] before:h-[8px] before:rounded-full before:bg-texthighlight-color'>Check insights</li>
                                </ul>
                            </div>       
                            <div className='pt-[20px] border border-2 border-x-0 border-b-0 '>
                                <h4 className='font-bold text-base pl-4 relative before:block before:absolute before:left-0 before:top-2 before:w-[8px] before:h-[8px] before:rounded-full before:bg-texthighlight-color'>No storms yet</h4>
                                <p className='text-left font-light text-sm mb-5'>Start a new AI enabled brainstorming session by clicking the button below</p>
                                <img src='/assets/images/arrow-bottom.png' className='mx-auto' />
                            </div>
                        </div>
                    :null}
                    {/* <div className="search-header text-center relative">
                        <span class="absolute inset-y-0 left-4 flex items-center pl-3">
                            <svg aria-hidden="true" class="w-4 h-4 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        </span>
                        <input id="search-box"  placeholder="Search storms..." className='w-[90%] mx-auto focus:ring-blue-600 focus:border-blue-500' onChange={filterBySearch} />
                    </div> */}
                    {filteredList && filteredList.map(channel => {
                        return (
                            <div key={channel.url} onClick={()=>handleSideBar()}
                            className={`${currentlyJoinedChannel && channel.url == currentlyJoinedChannel.url?'active':''} channel-list-item my-2 mx-auto`}>
                                <NavLink to={`/${(channel.url).replace('sendbird_group_channel_','')}`}
                                        
                                        className="channel-list-item-name flex items-center justify-between">
                                        <div className="img-ct overflow-hidden rounded-[20px] w-[40px] h-[40px] mr-2 ">
                                            <img src={channel.coverUrl} alt="" className='w-full h-full max-w-[100%] object-cover'/>
                                        </div>
                                        {channel?.lastMessage?.message != 'History Cleared' ? 
                                        <div className='content-ct w-[82%] h-[72px]'>
                                            <div className='flex items-center justify-between w-full mb-[5px]'>
                                                <h2 className="font-semibold text-[18px] chnamesidebar">{channel.name}</h2>
                                                {channel.unreadMessageCount ==0 ? <>{channel.lastMessage && channel.lastMessage.updatedAt!=0 ? <div className="font-medium text-xs text-gray-400">{timestampToTime(channel.lastMessage.updatedAt)}</div>:null}</>:<>{channel.lastMessage && channel.lastMessage.updatedAt!=0 ? <div className="font-medium text-xs text-primary-color">{timestampToTime(channel.lastMessage.updatedAt)}</div>:null}</>}
                                            </div>
                                            
                                            <div className='flex items-center justify-between'>
                                                {isHTML(channel?.lastMessage?.message) && checkTags ? <div className="last-message text-sm text-tertiary-color font-semibold max-w-[230px]">Typing...</div>:channel?.lastMessage?.messageType == 'admin' ?  '':<div className="last-message text-sm text-tertiary-color font-semibold max-w-[230px]">{channel?.lastMessage?.sender?.nickname ? channel?.lastMessage?.sender?.nickname +':' : null}  {channel?.lastMessage?.message ? channel?.lastMessage?.message : null}</div>}
                                                {isHTML(channel?.lastMessage?.message) ? null : channel.unreadMessageCount ==0 ?null:<div className="font-medium text-xs ml-2 bg-brand-color px-[8px] py-[0px] rounded-xl text-white-color flex justify-center items-center leading-[16px] text-[11px]">{channel.unreadMessageCount}</div>}
                                            </div>
                                        </div>:
                                        <div className='content-ct w-[82%]'>
                                            <div className='flex items-center justify-between w-full mb-[5px]'>
                                                <h2 className="font-semibold text-[18px] chnamesidebar">{channel.name}</h2>
                                                {channel.unreadMessageCount ==0 ? <>{channel.lastMessage && channel.lastMessage.updatedAt!=0 ? <div className="font-medium text-xs text-gray-400">{timestampToTime(channel.lastMessage.updatedAt)}</div>:null}</>:<>{channel.lastMessage && channel.lastMessage.updatedAt!=0 ? <div className="font-medium text-xs text-primary-color">{timestampToTime(channel.lastMessage.updatedAt)}</div>:null}</>}
                                            </div>
                                        
                                            <div className='flex items-center justify-between'>
                                                {isHTML(channel?.lastMessage?.message) && checkTags ? <div className="last-message text-sm text-tertiary-color font-semibold max-w-[230px]">Typing...</div>:channel?.lastMessage?.messageType == 'admin' ?  '':<div className="last-message text-sm text-tertiary-color font-semibold max-w-[230px]">{channel?.lastMessage?.sender?.nickname ? channel?.lastMessage?.sender?.nickname +':' : null}  {channel?.lastMessage?.message ? channel?.lastMessage?.message : null}</div>}
                                                {isHTML(channel?.lastMessage?.message) ? null : channel.unreadMessageCount ==0 ?null:<div className="font-medium text-xs ml-2 bg-brand-color px-[8px] py-[0px] rounded-xl text-white-color flex justify-center items-center leading-[16px] text-[11px]">{channel.unreadMessageCount}</div>}
                                            </div>
                                        </div>}
                                    
                                </NavLink>
                                <div>
                                
                                
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

             <div className="channel-type sm:block flex justify-between z-50 bg-secondary-color sm:z-1">
                <button className="w-full sm:w-[100%] xl:w-[97%] create-btn group sm:mr-0 mr-3 flex justify-center rounded-md bg-brand-color py-2 px-3 text-md font-semibold text-white hover:bg-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bottom-4 left-4 right-4 items-center" onClick={() => handleLoadMemberSelectionList()}>
                    Create <img src="/assets/images/create.svg" className="ml-2" alt="explore-icon" />
                </button>
                <button className="explore-btn group sm:hidden flex justify-center rounded-md bg-grey-500 py-2 px-3 text-md font-semibold text-secondary-color hover:bg-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bottom-4 left-4 w-full right-4 items-center" onClick={exploreChannel}>
                    Explore <img src="/assets/images/Discovery.svg" alt="explore-icon" />   
                </button>
            </div>
        </div >:null);
}

export default BotChannelList