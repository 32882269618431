import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import CategoryCard from './CategoryCard';
import ProfileIcon from './ProfileIcon';
import { SENDBIRD_INFO } from '../constants/constants';
import { NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import Notiflix from 'notiflix';
import Mobfooter from './Mobfooter';



const Categorylist = ({userNameInputValue,createChannel,handleLoadMemberSelectionList, HandleHomeBtn, HandleLibBtn}) => {
    const [existingOpt, setExistingOpt] = useState([]);
    const [active, setActive] = useState(0);
    const app_name = SENDBIRD_INFO.app_name;

    useEffect(()=>{
      getAllBot();
      Notiflix.Loading.remove();
    },[]);

    function getAllBot(){
      setActive(0);
      axios({
        url:`${SENDBIRD_INFO.newBotUrl}/get_bot_categories`,
        method: 'get',
        headers: {
          'Api-token': SENDBIRD_INFO.apiToken
        }
      })
      .then((res) => res)
      .then((response) => {
        // setExistingOpt(response.data.data);
        Notiflix.Loading.remove();
      })
      .catch((err) => {
          console.log(err.message+"file-error");
      }); 
    }
    
    const closeChannel = () => {
        document.querySelector('.channel').style.zIndex = '0';
    }
    
  return (
    <div className='lg:mx-5 bot-lib'>

       <Helmet>
          <title>${app_name && app_name} Explore Bots</title>
      </Helmet>
       
    </div>
  )
}

export default Categorylist