import React from 'react';
import { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import ProfileIcon from './ProfileIcon';
import { json, useNavigate,BrowserRouter as Router,Link,useLocation} from "react-router-dom";
import { getMessaging, getToken } from 'firebase/messaging';
import SendBird from 'sendbird';
import { SENDBIRD_INFO } from '../constants/constants';



const Mobfooter = ({handleLoadMemberSelectionList,HandleHomeBtn, HandleLibBtn, handleSearchBtn}) => {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const [userOpen, setUserOpen] = useState(false);
    const [navOpen, setNavOpen] = useState(false);
    const location = useLocation();
    const libraryStatus = process.env.REACT_APP_LIBRARYPAGE_STATUS == 'true' ? true : false

    const onNotification =() => {   
        const messaging = getMessaging();

        const appId = SENDBIRD_INFO.appId;
        const vapidKey = SENDBIRD_INFO.vapid_id;
        console.log('hi');
        const sbb = new SendBird({appId});
        console.log(user.uid);
        sbb.connect(user.uid, user.displayName, (user, error) => {

        Notification.requestPermission().then(permission => {

            if (permission === 'granted') {

                getToken(messaging, {vapidKey: vapidKey})
                .then(currentToken => {
                if (currentToken) {
                    sbb.registerGCMPushTokenForCurrentUser(currentToken, (response, error) => {
                    if(error) console.log(error);
                    console.log("Token Registered:", currentToken)
                    });
                }
                })
                .catch(err => {
                console.log('An error occurred while retrieving a token. ', err);
                });
            } else {
                getToken(messaging, {vapidKey: vapidKey})
                .then(currentToken => {
                if (currentToken) {
                    sbb.registerGCMPushTokenForCurrentUser(currentToken, (response, error) => {
                    if(error) console.log(error);
                    console.log("Token Registered:", currentToken)
                    setUserOpen(!userOpen)
                    navOpen && setNavOpen(!navOpen)
                    });
                }
                })
                .catch(err => {
                console.log('An error occurred while retrieving a token. ', err);
                });
            console.log('Unable to get permission to notify.');
            }
        })
        })
    };
    
    function gotoLogin(){
        navigate('/login');
    }


  return (
    <div className='mob-footer bg-secondary-color fixed bottom-0 sm:hidden'>
        <div className="inner-ct flex justify-between items-center p-3">
            <div onClick={HandleHomeBtn} >
                <div className="icon-ct flex flex-col justify-center items-center mt-[4px]">
                    <div className="img-ct">
                    {location.pathname.split('/')[1] ==''?
                        <svg className="mob-footer-icon-active" width="26" height="26" strokeWidth="2" viewBox="0 0 24 24" stroke='#2C2D98' xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z"/>
                        </svg>:
                        <svg width="26" height="26" strokeWidth="2" viewBox="0 0 24 24" stroke='#616161' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z"/>
                        </svg>}
                    </div>
                    <span className={`${location.pathname.split('/')[1] ==''? 'text-primary-color':'text-[#616161]'} menu-name text-[12px] block mt-[12px] font-semibold`}>Home</span>
                </div>
            </div>
            {libraryStatus ?
            <div onClick={HandleLibBtn} >
                <div className="icon-ct flex flex-col justify-center items-center mt-[4px]">
                    <div className="img-ct">
                    {location.pathname.split('/')[1] =='library'?
                        <svg className="mob-footer-icon-active" width="26" height="26" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 9.4985H4V4H9.5V9.4985ZM19.9985 9.4985H14.5V4H19.9985V9.4985ZM9.5 19.9985H4V14.4985H9.5V19.9985ZM19.9985 19.9985H14.5V14.4985H19.9985V19.9985Z"/>
                        </svg>:
                        <svg width="26" height="26" strokeWidth="2" viewBox="0 0 24 24" stroke='#616161' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.5 9.4985H4V4H9.5V9.4985ZM19.9985 9.4985H14.5V4H19.9985V9.4985ZM9.5 19.9985H4V14.4985H9.5V19.9985ZM19.9985 19.9985H14.5V14.4985H19.9985V19.9985Z"/>
                        </svg>}
                    </div>
                    <span className={`${location.pathname.split('/')[1] =='library'? 'text-primary-color':'text-[#616161]'} menu-name text-[12px] block mt-[12px] font-semibold`}>Library</span>
                </div>
            </div>:null}
            {/* <a onClick={() => user ? (handleLoadMemberSelectionList()) : (navigate("/login"))} >
                <div className="icon-ct flex flex-col justify-center items-center relative w-[67px] ml-[8px]" >
                    <div className="btn-ct w-[67px] h-[67px] bg-primary-color flex justify-center items-center absolute top-[-40px] left-[0px] rounded-[10px]">
                        <span className='text-[40px] text-secondary-color h-[67px]'>+</span>
                    </div>
                    <span className='menu-name text-[12px] text-[#616161] block mt-[35px]'>Create</span>
                </div>
            </a> */}
             {user ? 
                <div className='flex flex-col items-center'>
                    <a onClick={()=>{navigate("/chat");}} className='w-[35px] h-[35px] flex justify-center items-center rounded-[50%] mb-[4px] mt-[5px]'>
                        <svg className={`${location.pathname.split('/')[1] =='chat'? 'mob-footer-chat-icon-active':''}`} fill="none" width="32" height="32" viewBox="0 0 32 32" stroke='#616161' xmlns="http://www.w3.org/2000/svg" >
                            <path  d="M16.0002 28C13.3376 27.9996 10.7508 27.1137 8.64698 25.4818C6.54317 23.8499 5.04183 21.5647 4.37934 18.9859C3.71684 16.407 3.93082 13.6811 4.98757 11.2373C6.04433 8.79341 7.88385 6.7704 10.2165 5.48674C12.5492 4.20308 15.2426 3.73166 17.8726 4.14671C20.5026 4.56177 22.9198 5.83971 24.7438 7.77935C26.5678 9.719 27.6949 12.2102 27.9477 14.8607C28.2005 17.5112 27.5646 20.1706 26.1402 22.42L28.0002 28L22.4202 26.14C20.5008 27.3584 18.2736 28.0036 16.0002 28Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <g clipPath="url(#clip0_2835_521)">
                            <path d="M15.5 4C8.59625 4 3 9.59625 3 16.5C3 23.4037 8.59625 29 15.5 29C17.495 29.0027 19.4615 28.526 21.2337 27.61L26.0162 28.9437C26.2831 29.0182 26.5649 29.0204 26.8328 28.9501C27.1008 28.8798 27.3452 28.7395 27.5411 28.5436C27.737 28.3477 27.8773 28.1033 27.9476 27.8353C28.0179 27.5674 28.0157 27.2856 27.9412 27.0187L26.6062 22.24C27.5245 20.4662 28.0026 18.4974 28 16.5C28 9.59625 22.4037 4 15.5 4ZM15.5 5.875C18.3179 5.875 21.0204 6.99442 23.013 8.98699C25.0056 10.9796 26.125 13.6821 26.125 16.5C26.125 18.3375 25.6587 20.1037 24.7837 21.6712L24.5962 22.0087L25.9862 26.9887L21.0025 25.5987L20.665 25.7862C19.247 26.5746 17.6673 27.0278 16.0471 27.111C14.4268 27.1942 12.809 26.9053 11.3177 26.2664C9.82641 25.6274 8.50124 24.6555 7.44382 23.425C6.38639 22.1945 5.62479 20.7383 5.21742 19.1678C4.81005 17.5974 4.76773 15.9545 5.0937 14.3652C5.41967 12.7759 6.10528 11.2824 7.09795 9.99908C8.09062 8.7158 9.36398 7.6769 10.8204 6.96203C12.2768 6.24716 13.8776 5.87531 15.5 5.875ZM19.5625 17.75H13.94C13.7025 17.7501 13.4738 17.8403 13.3003 18.0025C13.1267 18.1646 13.0212 18.3866 13.005 18.6236C12.9888 18.8606 13.0631 19.0949 13.213 19.2791C13.3629 19.4634 13.5772 19.5839 13.8125 19.6162L13.94 19.625H19.5625C19.8 19.6249 20.0287 19.5347 20.2022 19.3725C20.3758 19.2104 20.4813 18.9884 20.4975 18.7514C20.5137 18.5144 20.4393 18.2801 20.2895 18.0959C20.1396 17.9116 19.9253 17.7911 19.69 17.7587L19.5625 17.75ZM19.5625 13.375H11.4312C11.1938 13.3754 10.9654 13.4658 10.7921 13.6281C10.6187 13.7904 10.5134 14.0123 10.4974 14.2492C10.4814 14.4861 10.5558 14.7202 10.7057 14.9044C10.8556 15.0885 11.0698 15.2089 11.305 15.2412L11.4312 15.25H19.5625C19.8 15.2499 20.0287 15.1597 20.2022 14.9975C20.3758 14.8354 20.4813 14.6134 20.4975 14.3764C20.5137 14.1394 20.4393 13.9051 20.2895 13.7209C20.1396 13.5366 19.9253 13.4161 19.69 13.3837L19.5625 13.375Z" fill="#616161"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_2835_521">
                            <rect width="13" height="12" transform="matrix(-1 0 0 1 21 11)"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </a>
                    <span className={`${location.pathname.split('/')[1] =='chat'? 'text-primary-color':'text-[#616161]'} menu-name text-[12px] block mt-[3px] font-semibold`}>Storms</span>
                </div>
                :
                <div className='flex flex-col items-center'>
                    <a onClick={()=>{navigate("/login");}} className='w-[40px] h-[40px] flex justify-center items-center rounded-[50%] mb-[4px]'> 
                        <svg className={`${location.pathname.split('/')[1] =='chat'? 'mob-footer-chat-icon-active':''}`} width="32" height="32" viewBox="0 0 32 32" stroke='#616161' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path  d="M16.0002 28C13.3376 27.9996 10.7508 27.1137 8.64698 25.4818C6.54317 23.8499 5.04183 21.5647 4.37934 18.9859C3.71684 16.407 3.93082 13.6811 4.98757 11.2373C6.04433 8.79341 7.88385 6.7704 10.2165 5.48674C12.5492 4.20308 15.2426 3.73166 17.8726 4.14671C20.5026 4.56177 22.9198 5.83971 24.7438 7.77935C26.5678 9.719 27.6949 12.2102 27.9477 14.8607C28.2005 17.5112 27.5646 20.1706 26.1402 22.42L28.0002 28L22.4202 26.14C20.5008 27.3584 18.2736 28.0036 16.0002 28Z" stroke="#616161" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <g clipPath="url(#clip0_2835_521)">
                            <path d="M15.5 4C8.59625 4 3 9.59625 3 16.5C3 23.4037 8.59625 29 15.5 29C17.495 29.0027 19.4615 28.526 21.2337 27.61L26.0162 28.9437C26.2831 29.0182 26.5649 29.0204 26.8328 28.9501C27.1008 28.8798 27.3452 28.7395 27.5411 28.5436C27.737 28.3477 27.8773 28.1033 27.9476 27.8353C28.0179 27.5674 28.0157 27.2856 27.9412 27.0187L26.6062 22.24C27.5245 20.4662 28.0026 18.4974 28 16.5C28 9.59625 22.4037 4 15.5 4ZM15.5 5.875C18.3179 5.875 21.0204 6.99442 23.013 8.98699C25.0056 10.9796 26.125 13.6821 26.125 16.5C26.125 18.3375 25.6587 20.1037 24.7837 21.6712L24.5962 22.0087L25.9862 26.9887L21.0025 25.5987L20.665 25.7862C19.247 26.5746 17.6673 27.0278 16.0471 27.111C14.4268 27.1942 12.809 26.9053 11.3177 26.2664C9.82641 25.6274 8.50124 24.6555 7.44382 23.425C6.38639 22.1945 5.62479 20.7383 5.21742 19.1678C4.81005 17.5974 4.76773 15.9545 5.0937 14.3652C5.41967 12.7759 6.10528 11.2824 7.09795 9.99908C8.09062 8.7158 9.36398 7.6769 10.8204 6.96203C12.2768 6.24716 13.8776 5.87531 15.5 5.875ZM19.5625 17.75H13.94C13.7025 17.7501 13.4738 17.8403 13.3003 18.0025C13.1267 18.1646 13.0212 18.3866 13.005 18.6236C12.9888 18.8606 13.0631 19.0949 13.213 19.2791C13.3629 19.4634 13.5772 19.5839 13.8125 19.6162L13.94 19.625H19.5625C19.8 19.6249 20.0287 19.5347 20.2022 19.3725C20.3758 19.2104 20.4813 18.9884 20.4975 18.7514C20.5137 18.5144 20.4393 18.2801 20.2895 18.0959C20.1396 17.9116 19.9253 17.7911 19.69 17.7587L19.5625 17.75ZM19.5625 13.375H11.4312C11.1938 13.3754 10.9654 13.4658 10.7921 13.6281C10.6187 13.7904 10.5134 14.0123 10.4974 14.2492C10.4814 14.4861 10.5558 14.7202 10.7057 14.9044C10.8556 15.0885 11.0698 15.2089 11.305 15.2412L11.4312 15.25H19.5625C19.8 15.2499 20.0287 15.1597 20.2022 14.9975C20.3758 14.8354 20.4813 14.6134 20.4975 14.3764C20.5137 14.1394 20.4393 13.9051 20.2895 13.7209C20.1396 13.5366 19.9253 13.4161 19.69 13.3837L19.5625 13.375Z" fill="#616161"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_2835_521">
                            <rect width="13" height="12" fill="#616161" transform="matrix(-1 0 0 1 21 11)"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </a>
                    <span className={`${location.pathname.split('/')[1] =='chat'? 'text-primary-color':'text-[#616161]'} menu-name text-[12px] block mt-[3px] font-semibold`}>Storms</span>
                </div>
             }  
            <div onClick={() => user ? (handleLoadMemberSelectionList()) : (navigate("/login"))}>
                <div className="icon-ct flex flex-col justify-center items-center mt-[5px]">
                    <div className="img-ct create-icon-ct ml-[10px]">
                    { <svg width="26" height="26" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4808 10.4343L3.40584 20.8491C3.35325 20.9225 3.27509 20.9735 3.18674 20.9922C3.0984 21.0109 3.00626 20.9958 2.92846 20.9499C2.85067 20.9041 2.79285 20.8308 2.76637 20.7445C2.7399 20.6582 2.74669 20.565 2.78542 20.4835L6.30102 13.082L1.42435 13.1055C1.35529 13.1177 1.28417 13.1095 1.21967 13.082C1.15517 13.0545 1.10006 13.0088 1.06106 12.9505C1.02206 12.8923 1.00085 12.8239 1.00003 12.7538C0.999197 12.6836 1.01878 12.6148 1.0564 12.5556L9.29609 1.16737C9.34408 1.09198 9.41831 1.03703 9.50443 1.01315C9.59056 0.989264 9.68248 0.99813 9.76245 1.03804C9.84242 1.07794 9.90477 1.14606 9.93748 1.22924C9.97018 1.31241 9.9709 1.40476 9.93952 1.48844L6.30102 9.87049H13.1059C13.1776 9.85368 13.2528 9.85917 13.3213 9.88623C13.3898 9.91329 13.4484 9.96062 13.4892 10.0219C13.5301 10.0832 13.5512 10.1555 13.5499 10.2291C13.5485 10.3027 13.5239 10.3746 13.4808 10.4343Z" strokeWidth="1.5"/>
                        <path d="M15.1533 5H23.1533"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19.1533 9L19.1533 1"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    }
                    </div>
                    <span className='menu-name text-[12px] text-[#616161] block mt-[12px] font-semibold'>Create</span>
                </div>
            </div>
            {user ? 
                <Link to="/account" >
                    <div className="icon-ct flex flex-col justify-center items-center mt-[4px]">
                        <div className="img-ct">
                        {location.pathname.split('/')[1] =='account' ? 
                        <svg className="mob-footer-icon-active" width="27" height="27" strokeWidth="2" viewBox="0 0 23 23"  xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.55556 7.44444C8.55556 6.53092 8.91845 5.65481 9.56441 5.00885C10.2104 4.3629 11.0865 4 12 4C12.9135 4 13.7896 4.3629 14.4356 5.00885C15.0815 5.65481 15.4444 6.53092 15.4444 7.44444C15.4444 8.35797 15.0815 9.23408 14.4356 9.88004C13.7896 10.526 12.9135 10.8889 12 10.8889C11.0865 10.8889 10.2104 10.526 9.56441 9.88004C8.91845 9.23408 8.55556 8.35797 8.55556 7.44444ZM4.33429 16.4454C5.18862 15.5911 6.34735 15.1111 7.55556 15.1111H16.4444C17.6527 15.1111 18.8114 15.5911 19.6657 16.4454C20.52 17.2997 21 18.4585 21 19.6667C21 20.2855 20.7542 20.879 20.3166 21.3166C19.879 21.7542 19.2855 22 18.6667 22H5.33333C4.71449 22 4.121 21.7542 3.68342 21.3166C3.24583 20.879 3 20.2855 3 19.6667C3 18.4585 3.47996 17.2997 4.33429 16.4454Z" />
                        </svg>:
                        <svg width="27" height="27" strokeWidth="2" viewBox="0 0 23 23" stroke='#616161' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.55556 7.44444C8.55556 6.53092 8.91845 5.65481 9.56441 5.00885C10.2104 4.3629 11.0865 4 12 4C12.9135 4 13.7896 4.3629 14.4356 5.00885C15.0815 5.65481 15.4444 6.53092 15.4444 7.44444C15.4444 8.35797 15.0815 9.23408 14.4356 9.88004C13.7896 10.526 12.9135 10.8889 12 10.8889C11.0865 10.8889 10.2104 10.526 9.56441 9.88004C8.91845 9.23408 8.55556 8.35797 8.55556 7.44444ZM4.33429 16.4454C5.18862 15.5911 6.34735 15.1111 7.55556 15.1111H16.4444C17.6527 15.1111 18.8114 15.5911 19.6657 16.4454C20.52 17.2997 21 18.4585 21 19.6667C21 20.2855 20.7542 20.879 20.3166 21.3166C19.879 21.7542 19.2855 22 18.6667 22H5.33333C4.71449 22 4.121 21.7542 3.68342 21.3166C3.24583 20.879 3 20.2855 3 19.6667C3 18.4585 3.47996 17.2997 4.33429 16.4454Z" />
                        </svg>}
                        </div>
                        <span className={`${location.pathname.split('/')[1] =='account'? 'text-primary-color':'text-[#616161]'} menu-name text-[12px] block mt-[12px] font-semibold`}>My Profile</span>
                    </div>
                </Link>: !loading ?
                <a onClick={gotoLogin}>
                    <div className="icon-ct flex flex-col justify-center items-center mt-[4px]">
                        <div className="img-ct">
                        <svg width="27" height="27" strokeWidth="2" viewBox="0 0 23 23" stroke='#616161' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.55556 7.44444C8.55556 6.53092 8.91845 5.65481 9.56441 5.00885C10.2104 4.3629 11.0865 4 12 4C12.9135 4 13.7896 4.3629 14.4356 5.00885C15.0815 5.65481 15.4444 6.53092 15.4444 7.44444C15.4444 8.35797 15.0815 9.23408 14.4356 9.88004C13.7896 10.526 12.9135 10.8889 12 10.8889C11.0865 10.8889 10.2104 10.526 9.56441 9.88004C8.91845 9.23408 8.55556 8.35797 8.55556 7.44444ZM4.33429 16.4454C5.18862 15.5911 6.34735 15.1111 7.55556 15.1111H16.4444C17.6527 15.1111 18.8114 15.5911 19.6657 16.4454C20.52 17.2997 21 18.4585 21 19.6667C21 20.2855 20.7542 20.879 20.3166 21.3166C19.879 21.7542 19.2855 22 18.6667 22H5.33333C4.71449 22 4.121 21.7542 3.68342 21.3166C3.24583 20.879 3 20.2855 3 19.6667C3 18.4585 3.47996 17.2997 4.33429 16.4454Z" />
                        </svg>

                        </div>
                        <span className={`${location.pathname.split('/')[1] =='login'? 'text-primary-color':'text-[#616161]'} menu-name text-[12px] block mt-[12px] font-semibold`}>Login</span>
                    </div>
                </a> :null
            }
            <div className={`absolute top-[-90px] right-[0px] min-w-[200px] md:right-6  mt-2 origin-bottom-right secondary-color rounded-md shadow-lg ring-1 ring-black z-20 ring-opacity-5 ${userOpen ? 'transition duration-500 ease-in-out transform opacity-100 scale-y-100' : 'transition duration-500 ease-in-out transform opacity-0 scale-y-0'}`}>
            <div className="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <span className="block px-2 py-2 text-md text-black-700" role="menuitem">
                    <span className="flex flex-col">
                        <a className='hover:text-primary-color cursor-pointer'>
                            <span className='py-2 px-1 text-tertiary-color block user-name2'>Hi! {user?.displayName}</span>
                        </a>
                        <a className="hover:text-primary-color" href="/logout">
                            <span className='py-2 px-1 text-tertiary-color'>Logout</span>
                        </a>
                        {
                            "Notification" in window ? (Notification.permission !== "granted") ?  
                            <a onClick={onNotification} className="hover:text-primary-color cursor-pointer">
                                <span className='py-2 px-1 text-tertiary-color'>Allow Notification</span>
                            </a>:null
                                    : null
                        }
                    </span>
                </span>
            </div>
            <div className="flex hidden">
                <button className="text-gray-800 dark:text-gray-800 hover:text-gray-800 inline-flex items-center justify-center p-3 rounded-md focus:outline-none">
                    <svg width="20" height="20" fill="currentColor" className="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path></svg>
                </button>
            </div>
        </div>
            {/* {user ? <ProfileIcon userid={user.uid} username={user.displayName }/> : null }  */}
        </div>
    </div>
  )
}

export default Mobfooter